@font-face {
    font-family: RiverAdventurer;
    src: url(../fonts/river_adventurer.ttf);
}
@font-face {
    font-family: MorthLand;
    src: url(../fonts/morth_land.ttf);
}
@font-face {
    font-family: gothamBold;
    src: url(../fonts/botham-bold.otf);
}
html,body,#root {
    height: 100%;
}
body {
    color: #fff;
    background: #000;
    font-size: 14px;
    font-family: MorthLand;
}
img {
    max-width: 100%;
}
a {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
.btn,
.btn:focus {
    box-shadow: none;
    outline: none;
}
button.btnCustom {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
}
.font-riverAdventurer {
    font-family: "RiverAdventurer";
    text-transform: uppercase;
}
.wrap {
    margin: 0 auto;
    min-height: 100%;
    min-width: 1770px;
    max-width: 1920px;
}
.btnDisabled {
    filter: grayscale(1);
}
.star {
    font-size: 0;
}
.star img {
    display: inline-block;
}
.charId {
    width: 200px;
    height: 48px;
    position: relative;
    color: #612500;
    font-size: 20px;
    padding: 3px 0 0 70px;
    background: url(../img/id-bar.png) no-repeat center center / contain;
}
.charId .element {
    width: 50px;
    position: absolute;
    left: 10px;
    top: -15px;
}
.charStamina,
.charXp {
    margin-bottom: 10px;
    width: 200px;
}
.processBar {
    height: 34px;
    padding: 4px 0 0 5px;
    background: url(../img/bg-process-bar.png) no-repeat left center / cover;
}
.processBar .percent {
    height: 20px;
    border-radius: 8px;
    background: url(../img/bg-process-bar-percent.png) no-repeat left center / 188px 20px;
}
.balance .item {
    background: #873800;
    border: 1px solid #fff;
    padding: 5px 30px 5px 50px;
    text-align: left;
    margin: 20px 30px 0 0;
    height: 50px;
    border-radius: 25px;
    position: relative;
}
.balance .item:not(:last-child) {
    margin-right: 50px;
}
.balance .symbol,
.balance .buyMore {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.balance .symbol {
    left: -20px;
    width: 60px;
}
.balance .buyMore {
    right: -10px;
    width: 28px;
}
.balance .text {
    line-height: 100%;
    font-size: 13px;
    letter-spacing: 1px;
}
.balance .number {
    color: #FFBC00;
    font-size: 23px;
    line-height: 100%;
    margin-top: 3px;
}
.myCrewFrame {
    width: 1720px;
    margin: 0 auto;
}
.btnBack {
    width: 65px;
    margin-right: 30px;
}
.metal-frame-style-1 {
    max-width: 280px;
    margin: 0 auto;
}
.metal-frame-style-1 .frameTop,
.metal-frame-style-1 .frameBottom {
    display: block;
    margin: 0 auto;
}
.metal-frame-style-1 .frameBottom {
    transform: scaleY(-1);
}
.metal-frame-style-1 .frameMiddle {
    padding: 0 20px;
    background: url(../img/metal-frame-style-1-middle.png) repeat-y center top / contain;
}
.metal-frame-style-1 .avataWrap {
    height: 205px;
    position: relative;
    top: 13px;
    overflow: hidden;
    z-index: 1;
}
.signSuccess {
    position: relative;
    width: 300px;
    height: 400px;
    padding-top: 45px;
    text-align: center;
    background: url(../img/char-frame.png) no-repeat center center / cover;
}
.signSuccess:before {
    content: "";
    width: 105px;
    height: 117px;
    position: absolute;
    left: -15px;
    top: -60px;
    background: url(../img/flame.png) no-repeat center center / cover;
}
.signSuccess .character {
    width: 210px;
}
.signSuccess .s-char {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.signSuccess .merc-broke {
    top: -10px;
    width: 260px;
}
.signSuccess .merc-cross {
    width: 290px;
    top: -10px;
    margin-left: 10px;
}
.signSuccess .merc-slinger {
    width: 230px;
    top: 20px;
    margin-left: 5px;
}
.signSuccess .merc-monie {
    width: 240px;
    top: 20px;
}
.signSuccess .merc-helsing {
    width: 240px;
    top: 25px;
    margin-left: -10px;
}
.signSuccess .charId {
    padding: 3px 30px 0 120px;
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 100%;
    height: 61px;
    color: #873800;
    font-size: 23px;
    text-align: left;
    line-height: 1;
}
.signSuccess .charId .star {
    margin-top: 3px;
}
.signSuccess .charId .star img {
    width: 18px;
    margin-right: 2px;
}
.signSuccess .charId .element {
    position: absolute;
    left: 40px;
    bottom: 0;
    width: 70px;
}
.buttonText {
    width: 150px;
    position: relative;
    font-family: gothamBold;
    padding: 0;
    background: none;
    border: none;
}
.buttonText .text {
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 18px;
    font-size: 20px;
}
.mercFrameMetal {
    width: 721px;
    height: 620px;
    margin: 0 auto;
    padding: 100px 70px 70px;
    position: relative;
    background: url(../img/metal-frame-style-2-medium.png) no-repeat center center / contain;
}
.mercFrameMetal .badgeFrame {
    top: -70px;
    width: 250px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.mercFrameMetal .warning {
    margin: 20px 0 0;
    text-align: center;
    color: #FFC069;
}
.mercFrameMetal .info {
    margin-left: 30px;
}
.mercFrameMetal .info .star,
.mercFrameMetal .info .charStamina {
    margin: 20px 0;
}
.mercFrameMetal .charId .element {
    width: 45px;
    left: 13px;
    top: -12px;
}
.mercFrameMetal .mercPortrait {
    width: 260px;
    min-height: 362px;
    position: relative;
}
.mercFrameMetal .mercPortrait img {
    width: 100%;
}
.mercFrameMetal .mercPortrait img.s-char {
    position: absolute;
    max-width: initial;
}
.mercFrameMetal .mercPortrait img.merc-broke {
    left: -40px;
    top: -70px;
    width: 320px;
}
.mercFrameMetal.large .mercPortrait img.merc-broke {
    width: 380px;
    top: -80px;
}
.mercFrameMetal .mercPortrait img.merc-cross {
    left: -40px;
    top: -70px;
    width: 360px;
}
.mercFrameMetal.large .mercPortrait img.merc-cross {
    width: 410px;
    left: -70px;
}
.mercFrameMetal .mercPortrait img.merc-monie {
    left: -10px;
    top: -20px;
    width: 290px;
}
.mercFrameMetal.large .mercPortrait img.merc-monie {
    width: 330px;
}
.mercFrameMetal .mercPortrait img.merc-slinger {
    top: -20px;
    left: -10px;
    width: 290px;
}
.mercFrameMetal.large .mercPortrait img.merc-slinger {
    width: 330px;
}
.mercFrameMetal .buttonAction {
    position: absolute;
    bottom: -22px;
    width: 170px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    background: none;
    border: none;
}
.mercFrameMetal .buttonAction .buttonNumber {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    font-family: 'RiverAdventurer';
    font-size: 30px;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    padding: 0 20px;
}
.mercFrameMetal .buttonAction img.symbol {
    width: 35px;
}
.mercFrameMetal .fee {
    width: 260px;
    font-size: 16px;
    padding-left: 45px;
    background: url(../img/icon-info.png) no-repeat left top / 30px;
}
.mercFrameMetal .sellOption .sellBtn {
    margin-left: 30px;
}
.mercFrameMetal .sellOption .sellBtn a {
    width: 90px;
    margin: 0 5px;
    display: inline-block;
}
.mercFrameMetal .torch {
    position: absolute;
    left: -18px;
    bottom: 15px;
    width: 70px;
}
.mercFrameMetal .torch.right {
    left: auto;
    right: -18px;
}
.mercFrameMetal .closeModal {
    position: absolute;
    top: -8px;
    right: -15px;
    width: 80px;
}
.mercFrameMetal.large {
    width: 1050px;
    height: 680px;
    background-image: url(../img/metal-frame-style-2-large.png);
}
.mercFrameMetal.large .fee {
    width: auto;
}
.mercFrameMetal.large .mercPortrait {
    width: 300px;
    min-height: 417px;
}
.mercFrameMetal.large .torch {
    left: 0;
    width: 80px;
}
.mercFrameMetal.large .torch.right {
    left: auto;
    right: 0;
}
.mercFrameMetal.large .inputGroup {
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
}
.mercFrameMetal.large .inputGroup .inputIcon {
    width: 333px;
    height: 82px;
    position: relative;
    padding: 0 100px 0 30px;
    background: url(../img/bg-frame-metal.png) no-repeat center center / cover;
}
.mercFrameMetal.large .inputGroup img.symbol {
    position: absolute;
    right: 30px;
    top: 50%;
    width: 60px;
    transform: translateY(-50%);
}
.mercFrameMetal.large .inputGroup input {
    border: none;
    color: #fff;
    background: none;
    font-size: 22px;
    width: 100%;
}
.mercFrameMetal.large .inputGroup input:focus {
    box-shadow: none;
    outline: none;
}
.mercFrameMetal.large .inputGroup .buttonText {
    margin-left: -20px;
}
header {
    width: 100% !important;
    max-width: 1720px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}
header .nav-item:not(:last-child) {
    margin-right: 10px;
}
header .nav-link {
    width: 150px;
    height: 105px;
    position: relative;
    color: #FFD591;
    text-align: center;
    font-size: 17px;
    letter-spacing: 1px;
    padding: 23px 5px 10px 5px;
    overflow: hidden;
    cursor: pointer;
    background: url(../img/bg-menu-item.png) no-repeat center top / contain;
}
header .nav-link:hover,
header .nav-link:focus,
header .nav-link.active {
    color: #fff;
}
header .nav-link:before {
    content: "";
    position: absolute;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background: url(../img/bg-menu-item-active.png) no-repeat center top / contain;
}
header .nav-link:hover:before,
header .nav-link.active:before {
    top: 0;
}
header .nav-link.logo {
    padding-top: 10px;
}
header .nav-link span {
    position: relative;
    z-index: 1;
}
header .nav-link span.quantity {
    color: #FFBC00;
    display: inline-block;
    margin-right: 5px;
}
/*========================== INTRO PAGE ==========================*/
#introPage {
    padding: 80px 20px;
    background: url(../img/bg-home.jpg) no-repeat 50% / cover;
}
#introPage a {
    display: block;
    margin: 0 auto;
}
#introPage a:hover {
    transform: scale(1.05);
}
#introPage .menuMarket {
    width: 330px;
    margin-top: 50px;
}
#introPage .menuMyCrew {
    width: 360px;
    margin: 80px 0 0;
}
#introPage .logo {
    width: 235px;
    margin-bottom: 50px;
}
#introPage .menuMercCenter {
    width: 400px;
}
#introPage .menuBattleField {
    width: 450px;
}
#introPage .menuFow {
    width: 350px;
    margin: 40px 0 0 40px;
}
/*========================== MERC CENTER PAGE ==========================*/
#mercCenterPage {
    display: flex;
    flex-direction: column;
    background: url(../img/bg-merc-center.jpg) no-repeat center center / cover;
}
#mercCenterPage .mystery {
    width: 300px;
}
#mercCenterPage .totalContract {
    width: 350px;
    height: 185px;
    color: #873800;
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    padding: 20px 0 0 20px;
    background: url(../img/bg-total-contract.png) no-repeat center center / cover;
}
#mercCenterPage .totalContract .text {
    display: block;
    font-size: 20px;
}
#mercCenterPage .buyContract .number {
    font-size: 40px;
    margin-right: 5px;
    position: relative;
    top: 8px;
}
#mercCenterPage .buyContract .text {
    display: block;
    font-size: 20px;
}
#mercCenterPage .buyContract .icon {
    width: 55px;
}
#mercCenterPage .buyContract .btnBuy {
    height: 80px;
    margin-left: 25px;
}
#mercCenterPage .signContract {
    margin-left: 10px;
}
#mercCenterPage .withDrawText {
    text-align: center;
}
#mercCenterPage .withDrawText img {
    cursor: pointer;
}
#mercCenterPage .buyContract a {
    display: inline-block;
    width: 180px;
    margin-bottom: 20px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#mercCenterPage .buyContract .package {
    width: 140px;
}
#mercCenterPage .buyContract a:hover {
    transform: scale(1.05);
}
/*========================== BUY PACKAGE PAGE ==========================*/
#buyPackagePage {
    padding: 80px;
    background: url(../img/bg-buy-package.jpg) no-repeat center bottom / cover;
}
#buyPackagePage .item {
    position: relative;
    display: inline-block;
    text-align: left;
}
#buyPackagePage .packageName,
#buyPackagePage .decor-1,
#buyPackagePage .decor-2,
#buyPackagePage .price {
    position: absolute;
    z-index: 1;
}
#buyPackagePage .mainImg {
    width: 670px;
}
#buyPackagePage .packageName {
    font-family: RiverAdventurer;
    text-transform: uppercase;
    top: 80px;
    left: 80px;
    font-size: 30px;
    line-height: 1.2;
}
#buyPackagePage .packageName .remain {
    color: #FFC53D;
    font-size: 20px;
    margin-top: 10px;
}
#buyPackagePage .decor-1 {
    top: -20px;
    left: -65px;
}
#buyPackagePage .decor-2 {
    left: -20px;
    bottom: 30px;
    width: 370px;
}
#buyPackagePage .price {
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}
#buyPackagePage .price:hover {
    transform: translateX(-50%) scale(1.1);
}
/*========================== BATTLEFIELD PAGE ==========================*/
#battleFieldPage {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    background: url(../img/bg-battle-field.jpg) no-repeat center center / cover;
}
#battleFieldPage #content {
    margin-top: 130px;
    padding: 0 10px;
    position: relative;
}
#battleFieldPage .fire-battle {
    position: absolute;
    left: -70px;
    bottom: 0;
    width: 510px;
}
#battleFieldPage .fire-battle.right {
    left: auto;
    right: -70px;
}
#battleFieldPage .battleFieldFrame {
    width: 1720px;
    height: 770px;
    margin: 0 auto;
    position: relative;
    padding: 0 160px;
    background: url(../img/battle-field-frame.png) no-repeat center center / cover;
}
#battleFieldPage .yourTeam {
    position: absolute;
    padding-top: 195px;
    left: 50%;
    transform: translateX(-50%);
    top: -190px;
    width: 600px;
    height: 337px;
    background: url(../img/your-team-frame.png) no-repeat center center;
    background-size: 437px 337px;
    z-index: 1;
}
#battleFieldPage .yourTeam .merc {
    position: relative;
    width: 110px;
    height: 155px;
    padding: 10px 8px 0 12px;
    margin: 0 6px;
    cursor: pointer;
    border-radius: 7px;
    background: url(../img/char-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .yourTeam .merc .text {
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding: 5px 0;
    line-height: 1;
    position: relative;
    top: -5px;
    background: url(../img/bg-text-selected.png) no-repeat center center / 90px 26px;
}
#battleFieldPage .yourTeam .merc:hover,
#battleFieldPage .yourTeam .merc.selected {
    transform: scale(1.2);
    margin: 0 15px;
    box-shadow: 0px 0px 8px #cca864;
}
#battleFieldPage .yourTeam .merc .element {
    position: absolute;
    width: 30px;
    left: 0;
    top: 0;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 1;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap {
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap img {
    width: 115px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8px;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap.merc-broke img {
    top: -20px;
    width: 130px;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap.merc-cross img {
    top: -20px;
    width: 150px;
}
#battleFieldPage .yourTeam .merc .star {
    text-align: center;
    padding: 6px 0;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / cover;
}
#battleFieldPage .yourTeam .merc .star img {
    width: 12px;
}
#battleFieldPage .mercenary {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 75px;
    height: 185px;
    padding: 0 0 15px;
    border-radius: 0 0 20px 20px;
    width: 600px;
    background: url(../img/bg-mercenary.png) no-repeat center bottom / cover;
}
#battleFieldPage .mercenary .element {
    width: 40px;
    margin: 0 8px;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 6px;
}
#battleFieldPage .mercenary .arrow-left {
    transform: rotateY(180deg);
}
#battleFieldPage .winingRate {
    color: #E87F45;
    font-size: 20px;
    margin-bottom: 60px;
}
#battleFieldPage .winingRate img {
    width: 270px;
}
#battleFieldPage .winingRate .text {
    margin-top: 10px;
}
#battleFieldPage .fightFrame {
    width: 270px;
    height: 185px;
    text-align: center;
    background: url(../img/fight-frame.png) no-repeat center center / cover;
}
#battleFieldPage .fightFrame .btnFight {
    display: inline-block;
    position: relative;
    top: -20px;
    background: url(../img/swords.png) no-repeat center center / contain;
}
#battleFieldPage .fightFrame .btnFight img {
    width: 140px;
    padding: 10px;
    cursor: pointer;
    animation: glow 3s ease infinite;
}
#battleFieldPage .fightFrame .speedBattle {
    display: block;
    width: 130px;
    margin: 0 auto;
    position: relative;
    top: -25px;
}
@keyframes glow {
    0% {
        transform: scale(.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.9);
    }
}
#battleFieldPage .mainImg {
    width: 320px;
    margin-bottom: 20px;
    position: relative;
}
#battleFieldPage .s-char .mainImg img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 380px;
    max-width: initial;
}
#battleFieldPage .s-char.merc-cross .mainImg img {
    bottom: -50px;
    left: -60px;
}
#battleFieldPage .s-char.merc-broke .mainImg img {
    left: -40px;
    width: 360px;
}
#battleFieldPage .s-char.cri-catnip .mainImg img {
    width: 330px;
    bottom: -30px;
}
#battleFieldPage .mercTeam .charId {
    width: 320px;
    height: 75px;
    margin: 180px 0 10px;
    padding-left: 125px;
    font-size: 32px;
    line-height: 60px;
}
#battleFieldPage .mercTeam .charId .element {
    width: 90px;
    left: 15px;
    top: -28px;
}
#battleFieldPage .mercTeam.charInfo {
    width: 200px;
}
#battleFieldPage .mercTeam .s-char .charInfo {
    position: relative;
    top: -70px;
}
#battleFieldPage .mercTeam .charInfo > div:not(:last-child) {
    margin-bottom: 20px;
}
#battleFieldPage .mercTeam .charInfo .text {
    font-size: 15px;
    margin-bottom: 10px;
}
#battleFieldPage .mercTeam .remainingMatches {
    color: #ffcf20;
    font-size: 16px;
    text-align: center;
}
#battleFieldPage .mercTeam .remainingMatches span {
    font-size: 30px;
    display: block;
}
#battleFieldPage .processBar {
    height: 32px;
}
#battleFieldPage .criTeam .listCri {
    padding-top: 30px;
    margin: 0 60px;
}
#battleFieldPage .criTeam li {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .criTeam li:not(:last-child) {
    margin-bottom: 20px;
}
#battleFieldPage .criTeam li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .7;
    background: url(../img/cri-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .criTeam li:hover,
#battleFieldPage .criTeam li.selected {
    transform: scale(1.2);
}
#battleFieldPage .criTeam li:hover:before,
#battleFieldPage .criTeam li.selected:before {
    opacity: 1;
}
#battleFieldPage .criTeam .thumbCriWrap {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow: hidden;
    border-radius: 18px;
    z-index: 1;
}
#battleFieldPage .criTeam .thumbCriWrap img {
    width: 140px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
}
#battleFieldPage .criTeam .element {
    position: absolute;
    width: 30px;
    top: -6px;
    left: -10px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 2;
}
#battleFieldPage .criTeam .text {
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding: 5px 0;
    line-height: 1;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: url(../img/bg-text-selected.png) no-repeat center center / 80px 22px;
}
/*========================== MY CREW PAGE ==========================*/
#myCrewPage {
    display: flex;
    flex-direction: column;
    background: #1e0e0e url(../img/bg-my-crew-1.jpg) no-repeat center center / cover;
}
#myCrewPage #content {
    margin-top: 30px;
}
#myCrewPage .crewBoard {
    width: 930px;
    height: auto;
    padding-top: 85px;
    background-image: url(../img/crew-board-1.png);
}
#myCrewPage .crewBoard:before {
    top: 320px;
    background-image: url(../img/crew-board-bg-repeat-1.png);
}
#myCrewPage .crewBoard .filterElement {
    left: 30px;
    top: 130px;
}
#myCrewPage .crewBoard .filterRarity {
    padding: 15px 120px;
}
#myCrewPage .crewBoard.crewBoardHeader {
    height: auto;
    background: none;
    padding: 0 120px 0 90px;
    overflow: initial;
    margin-bottom: -95px;
    position: relative;
    z-index: 1;
}
#myCrewPage .crewBoard.crewBoardHeader:before {
    display: none;
}
#myCrewPage .crewBoard.crewBoardHeader .totalCrew {
    color: #FFE7BA;
    background: #511c06;
    padding: 10px 20px;
    margin-top: 32px;
    font-size: 16px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew {
    width: 490px;
    height: auto;
    padding: 0 90px 10px 0px;
    position: relative;
    background: url(../img/bg-team-group.png) no-repeat center center / cover;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .btnTeamSetting {
    position: absolute;
    top: -10px;
    right: -40px;
    outline: none;
    box-shadow: none;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .element {
    width: 25px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .merc {
    margin-bottom: 0;
    width: 80px;
    height: 112px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .merc:before {
    opacity: 1;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap {
    height: 90px;
    width: 70px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img {
    width: 90px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-cardinal {
    top: -5px;
    left: 5px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-helsing {
    width: 105px;
    top: -10px;
    left: -8px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-monie {
    width: 105px;
    top: -10px;
    left: 5px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-broke {
    width: 115px;
    top: -25px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-cross {
    width: 125px;
    top: -25px;
    left: 5px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .thumbMercWrap img.merc-slinger {
    top: -10px;
    width: 100px;
    left: 5px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .info {
    width: 75px;
    margin-left: 3px;
}
#myCrewPage .crewBoard.crewBoardHeader .listCrew .star img {
    width: 9px;
}
#myCrewPage .crewBoard .listCrew {
    padding-left: 105px;
}
#myCrewPage .mCustomScrollbar .mCustomScrollBox {
    padding-right: 95px;
}
#myCrewPage .crewBoard .listCrew .merc {
    height: 227px;
    margin-bottom: 20px;
}
#myCrewPage .crewBoard .listCrew .info {
    padding: 5px;
}
#myCrewPage .crewBoard .listCrew .info > div {
    margin: 0;
}
#myCrewPage .crewBoard .listCrew .star img {
    width: 20px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap {
    height: 192px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img {
    width: 220px;
    position: relative;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img.merc-helsing {
    width: 240px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img.merc-cardinal {
    top: -10px;
    left: 10px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img.merc-monie {
    width: 240px;
    left: 10px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img.merc-broke {
    width: 270px;
    top: -60px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img.merc-cross {
    width: 300px;
    top: -60px;
    left: 10px;
}
#myCrewPage .mercFullInfo .star {
    margin: 20px 0 40px;
}
#myCrewPage .mercFullInfo .star img {
    width: 27px;
}
#myCrewPage .charStamina,
#myCrewPage .charXp {
    margin-bottom: 20px;
}
#myCrewPage .rightSide {
    margin-bottom: 50px;
}
#myCrewPage .mercFullInfo .mercPortrait {
    width: 330px;
    height: 460px;
    margin: 0 50px 0 20px;
    position: relative;
}
#myCrewPage .mercFullInfo .mercPortrait img {
    width: 330px;
    max-width: initial;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
#myCrewPage .mercFullInfo .mercPortrait img.merc-helsing {
    width: 350px;
}
#myCrewPage .mercFullInfo .mercPortrait img.merc-monie {
    width: 370px;
}
#myCrewPage .mercFullInfo .mercPortrait img.merc-broke {
    width: 400px;
    margin-top: -30px;
}
#myCrewPage .mercFullInfo .mercPortrait img.merc-cross {
    width: 440px;
    margin-left: 10px;
}
#myCrewPage .mercFullInfo .text {
    margin-bottom: 10px;
}
#myCrewPage .upgrade {
    width: 330px;
    text-align: center;
    margin: 0 50px 0 20px;
}
#myCrewPage .mCustomScrollbar .mCSB_scrollTools {
    right: 34px;
    top: 0;
}
/*========================== TEAM SETTING PAGE ==========================*/
#teamSettingPage {
    display: flex;
    flex-direction: column;
    background: #1e0e0e url(../img/bg-my-crew.jpg) no-repeat 50%/cover;
}
#teamSettingPage .mercFullInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 70px;
    width: 520px;
}
#teamSettingPage .mercFullInfo .star {
    margin: 10px 0;
}
#teamSettingPage .mercFullInfo .star img {
    width: 27px;
}
#teamSettingPage .mercFullInfo .mercPortrait {
    width: 330px;
    height: 460px;
    margin-bottom: 30px;
    position: relative;
    top: 10px;
    text-align: center;
}
#teamSettingPage .mercFullInfo .mercPortrait img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
}
#teamSettingPage .mercFullInfo .mercPortrait img.merc-monie {
    width: 350px;
    max-width: initial;
}
#teamSettingPage .mercFullInfo .mercPortrait img.merc-broke {
    max-width: initial;
    width: 400px;
    margin-top: -40px;
    margin-left: -10px;
}
#teamSettingPage .mercFullInfo .mercPortrait img.merc-cross {
    max-width: initial;
    width: 420px;
}
#teamSettingPage .mercFullInfo .mercPortrait img.merc-helsing {
    margin-left: -20px;
}
#teamSettingPage .mercFullInfo .text {
    margin-bottom: 10px;
}
#teamSettingPage .selectedTeam {
    width: 470px;
    height: 528px;
    position: relative;
    margin: 90px 0 30px;
    background: url(../img/selected-team-frame.png) no-repeat center top / cover;
}
#teamSettingPage .selectedTeam:before {
    content: "";
    position: absolute;
    width: 325px;
    height: 320px;
    left: 0;
    top: -320px;
    left: 50%;
    transform: translateX(-50%);
    background: url(../img/bg-link.png) repeat-y center bottom / contain;
}
#teamSettingPage .selectedTeam .imgMyTeam {
    position: absolute;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
    top: -150px;
}
#teamSettingPage .selectedTeam .listMerc {
    position: relative;
    z-index: 1;
    padding: 0 10px;
}
#teamSettingPage .selectedTeam .listMerc .merc {
    padding: 15px 15px 30px 15px;
    position: relative;
}
#teamSettingPage .selectedTeam .listMerc .element {
    position: absolute;
    width: 55px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 12px;
    left: -20px;
    top: 3px;
    z-index: 1;
}
#teamSettingPage .selectedTeam .listMerc .buttonRemove {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;
    width: 100px;
    z-index: 1;
    cursor: pointer;
}
#teamSettingPage .selectedTeam .selectedMercFrame {
    height: 215px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    background: url(../img/selected-merc-frame.png) no-repeat center center / cover;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap {
    height: 200px;
    width: 170px;
    margin-bottom: 8px;
    overflow: hidden;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img {
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 220px;
    position: relative;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-helsing {
    width: 240px;
    top: -15px;
    left: -13px;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-cardinal {
    left: 10px;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-monie {
    width: 240px;
    top: -20px;
    left: 10px;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-broke {
    width: 270px;
    top: -60px;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-cross {
    width: 290px;
    top: -50px;
    left: 10px;
}
#teamSettingPage .selectedTeam .selectedThumbMercWrap img.merc-slinger {
    width: 250px;
    top: -20px;
    left: 10px;
}
#teamSettingPage .selectedTeam .star {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    width: 150px;
    text-align: center;
    height: 45px;
    padding-top: 8px;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / 150px 45px;
}
#teamSettingPage .selectedTeam .star img {
    width: 15px;
}
#teamSettingPage .buttonConfirmTeam {
    width: 190px;
    margin: 30px auto 10px;
    display: block;
    cursor: pointer;
}
/*========================== UPGRADE ==========================*/
#upgradePage {
    background: url(../img/bg-upgrade.jpg) no-repeat center bottom / cover;
}
#upgradePage .upgradeMerc {
    position: relative;
    right: -90px;
    width: 830px;
    height: 660px;
    padding: 100px 100px 50px 150px;
    background: url(../img/bg-wood.png) no-repeat center center / cover;
}
#upgradePage .upgradeMerc .badgeFrame {
    position: absolute;
    left: 250px;
    top: -80px;
    width: 260px;
}
#upgradePage .upgradeMerc .howto {
    position: absolute;
    width: 215px;
    text-align: center;
    color: #FFC069;
    left: 270px;
    top: 65px;
}
#upgradePage .upgradeMerc .mercFullInfo {
    margin-bottom: 30px;
}
#upgradePage .upgradeMerc .mercFullInfo .info {
    padding-top: 30px;
}
#upgradePage .upgradeMerc .mercFullInfo .star {
    margin:  20px 0 30px;
}
#upgradePage .upgradeMerc .mercFullInfo .text {
    font-size: 23px;
    color: #D1462D;
    height: 196px;
}
#upgradePage .upgradeMerc .mercFullInfo .text > div:last-child {
    color: #ffb630;
    font-family: 'MorthLand';
    font-size: 14px;
}
#upgradePage .upgradeMerc .mercPortrait {
    position: relative;
    width: 250px;
    height: 350px;
}
#upgradePage .upgradeMerc .mercPortrait img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
#upgradePage .upgradeMerc .mercPortrait img.merc-helsing {
    max-width: initial;
    width: 270px;
}
#upgradePage .upgradeMerc .mercPortrait img.merc-monie {
    max-width: initial;
    width: 280px;
}
#upgradePage .upgradeMerc .mercPortrait img.merc-broke {
    max-width: initial;
    width: 300px;
    margin-top: -20px;
}
#upgradePage .upgradeMerc .mercPortrait img.merc-cross {
    max-width: initial;
    width: 330px;
    margin-left: 20px;
}
#upgradePage .upgradeMerc .mercPortrait img.merc-slinger {
    max-width: initial;
    width: 280px;
}
#upgradePage .upgradeMerc .totalCost {
    font-size: 40px;
    line-height: 1;
}
#upgradePage .upgradeMerc .levelUp {
    width: 250px;
    text-align: center;
    position: relative
}
#upgradePage .upgradeMerc .levelUp .btn {
    padding: 0;
    width: 110px;
}
#upgradePage .upgradeMerc .levelUp .note {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    white-space: nowrap;
    display: none;
}
#upgradePage .upgradeMerc .levelUp.disable button {
    filter: grayscale(1);
}
#upgradePage .upgradeMerc .levelUp.disable .note {
    display: block;
}
#upgradePage .crewBoard .listCrew .thumbMercWrap {
    height: 161px;
}
#upgradePage .crewBoard .listCrew .info {
    padding: 5px;
}
#upgradePage .crewBoard .listCrew .merc {
    margin-bottom: 20px;
}
#upgradePage .crewBoard .listCrew .thumbMercWrap img.merc-broke {
    width: 220px;
}
#upgradePage .listMercUpgrade {
    margin: 20px 0 30px;
}
#upgradePage .listMercUpgrade ul {
    margin: 0 0 0 30px;
}
#upgradePage .listMercUpgrade ul li {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#upgradePage .listMercUpgrade ul li:not(:last-child) {
    margin-bottom: 30px;
}
#upgradePage .listMercUpgrade ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../img/square-frame-plus.png) no-repeat center center / cover;
}
#upgradePage .listMercUpgrade .thumbMercWrap {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow: hidden;
    border-radius: 18px;
    z-index: 1;
}
#upgradePage .listMercUpgrade .thumbMercWrap img {
    width: 100px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-helsing {
    top: -10px;
    width: 120px;
    margin-left: -10px;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-cardinal {
    margin-left: 5px;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-monie {
    width: 120px;
    top: -10px;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-broke {
    width: 120px;
    top: -25px;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-cross {
    width: 135px;
    top: -25px;
    margin-left: 5px;
}
#upgradePage .listMercUpgrade .thumbMercWrap img.merc-slinger {
    width: 110px;
    top: -10px;
}
#upgradePage .listMercUpgrade .element {
    position: absolute;
    width: 30px;
    top: -6px;
    left: -10px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 2;
}
#upgradePage .upgradeMerc .mercMystery {
    width: 250px;
}
/*========================== SELL MERC PAGE ==========================*/
#sellMercPage {
    background: url(../img/bg-sell-merc.jpg) no-repeat center center / cover;
}
/*========================== MARKET ==========================*/
#marketPage {
    background: url(../img/bg-market.jpg) no-repeat center center / cover;
}
#marketPage .mercProfile {
    text-align: center;
    width: 280px;
}
#marketPage .mercProfile .mercName {
    height: 70px;
    font-size: 18px;
    color: #FFC671;
    background: url(../img/bg-frame-metal.png) no-repeat center center / contain;
}
#marketPage .mercProfile .action a {
    display: inline-block;
    width: 110px;
    margin: 20px 10px;
}
#marketPage .myCrewFrame {
    width: 1720px;
    margin: 0 auto;
}
#marketPage .crewBoard {
    width: 1440px;
    margin-bottom: 100px;
    padding-left: 50px;
}
#marketPage .crewBoard:before {
    display: none;
}
#marketPage .crewBoard .listCrew {
    height: 775px;
    padding-left: 55px;
}
#marketPage .crewBoard .listCrew .merc {
    margin-bottom: 50px;
    height: 197px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap {
    width: 133px;
    height: 130px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img {
    width: 140px;
    position: relative;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img.merc-cross {
    width: 190px;
    top: -35px;
    left: 10px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img.merc-broke {
    width: 175px;
    top: -40px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img.merc-helsing {
    width: 160px;
    top: -10px;
    left: -10px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img.merc-monie {
    width: 160px;
    top: -15px;
}
#marketPage .crewBoard .listCrew .thumbMercWrap img.merc-slinger {
    width: 160px;
    top: -15px;
}
#marketPage .crewBoard .listCrew .info {
    width: 133px;
    background-size: contain;
    padding: 5px 5px 21px;
}
#marketPage .crewBoard .listCrew .buttonAction {
    width: 65px;
    bottom: -22px;
}
#marketPage .crewBoard .listCrew .info > div {
    margin: 2px 0 5px;
}
#marketPage .crewBoard .listCrew .col {
    width: 12.5%;
    flex: 0 0 auto;
}
#marketPage .crewBoard .listCrew .element {
    width: 35px;
}
#marketPage .mCustomScrollbar .mCustomScrollBox {
    padding-right: 55px;
}
/*========================== MY SHOP ==========================*/
#myshopPage {
    background: url(../img/bg-my-shop.png) no-repeat center bottom / cover;
}
#myshopPage .crewBoard {
    width: 1320px;
    padding-left: 20px;
    margin-bottom: 100px;
}
#myshopPage .crewBoard.metalFrame .filterElement {
    left: 2px;
}
#myshopPage .crewBoard .listCrew .thumbMercWrap {
    height: 154px;
}
/*========================== BATTLE HISTORY ==========================*/
#battleHistoryPage {
    background: url(../img/bg-battle-history.jpg) no-repeat center center / cover;
}
#battleHistoryPage .myCrewFrame {
    padding: 50px 0;
}
.tableWrap {
    position: relative;
    margin-right: 30px;
}
.tableWrap .tableBadge {
    position: absolute;
    z-index: 2;
    left: 75px;
    top: -15px;
    width: 200px;
}
.tableWrap .badgeStatus {
    width: 145px;
}
.tableWrap .inner {
    color: #FED85A;
    position: relative;
    text-align: center;
    padding: 0 30px;
}
.tableWrap .inner:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../img/bg-frame-1-center.png) repeat-y center center / contain;
}
.tableWrap .inner .overview {
    background: #4a1406;
    padding: 10px 70px;
    position: relative;
}
.tableWrap .inner .overview .userId {
    margin-right: auto;
    text-align: left;
    background: #35140A;
    border-radius: 0 15px 15px 0;
}
.tableWrap .inner .overview .userId .item {
    margin: 20px 30px;
}
.tableWrap .inner .overview .userId .number {
    font-size: 20px;
    color: #FFD591;
}
.tableWrap .inner .overview .number {
    font-size: 25px;
    color: #FFE7BA;
    margin: 5px 0 0;
}
.tableWrap .inner .overview .text {
    color: #AD4E00;
    font-size: 16px;
}
.tableWrap .inner .tableHeader {
    padding-right: 85px;
}
.tableWrap .inner .tableHeader,
.tableWrap .inner .tableContent {
    background: #271910;
    position: relative;
}
.tableWrap .inner .tableContent .noRecord {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #180101;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tableWrap .inner table {
    color: #FFA940;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    table-layout: fixed;
}
.tableWrap .inner table a {
    color: #FFA940;
}
.tableWrap .inner table thead {
    background: #271810;
    color: #D46B08;
}
.tableWrap .inner table tbody tr {
    background: #180101;
}
.tableWrap .inner table thead th,
.tableWrap .inner table tbody tr td {
    border: none;
}
.tableWrap .inner table thead tr th {
    position: relative;
    padding: 10px;
}
.tableWrap .inner table tbody tr td {
    position: relative;
    padding: 20px 10px;
}
.tableWrap .inner table tbody tr td:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: #271910;
}
.tableWrap .inner table .status {
    font-size: 18px;
    text-transform: uppercase;
}
.tableWrap .inner table .status.win {
    color: #FED85A;
}
.tableWrap .inner table .status.loss {
    color: #bbb;
}
.tableWrap [class*="decor-"] {
    position: absolute;
    z-index: 2;
}
.tableWrap .corner {
    width: 90px;
}
.tableWrap .decor-bottom-left {
    left: -7px;
    bottom: -5px;
}
.tableWrap .decor-bottom-right {
    right: -7px;
    bottom: -5px;
    transform: scaleX(-1);
}
.tableWrap .decor-top-left {
    left: -7px;
    top: -5px;
    transform: rotate(90deg);
}
.tableWrap .decor-top-right {
    right: -7px;
    top: -5px;
    transform: rotate(180deg);
}
.tableWrap .decor-middle-left {
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
}
.tableWrap .decor-middle-right {
    right: -15px;
    top: 50%;
    transform: translateY(-50%) scaleX(-1);
}
.tableWrap .decor-bottom-center {
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
}
.tableWrap .decor-fire-left {
    left: -45px;
    bottom: -15px;
}
.tableWrap .decor-fire-right {
    right: -45px;
    bottom: -15px;
}
.tableWrap .inner .mCustomScrollbar {
    height: 550px;
}
.tableWrap .mCustomScrollbar .mCSB_container {
    padding: 0;
}
.tableWrap .mCustomScrollbar .mCSB_scrollTools {
    background: #1D140E;
    top: 0;
    border-radius: 5px;
}
/*========================== CREW BOARD (LIST CREWS) ==========================*/
.crewBoard {
    width: 730px;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url(../img/crew-board.png) no-repeat top center / contain;
}
.crewBoard:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 210px;
    background: url(../img/crew-board-bg-repeat.png) repeat-y center top / contain;
}
.crewBoard .filterRarity {
    color: #D46B08;
    padding: 28px 75px 30px 75px;
    font-size: 15px;
}
.crewBoard .filterRarity .item {
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.crewBoard .filterRarity .item:hover,
.crewBoard .filterRarity .item.active {
    color: #FFE7BA;
}
.crewBoard .filterElement {
    position: absolute;
    left: 12px;
    top: 70px;
    z-index: 1;
}
.crewBoard .filterElement img {
    width: 55px;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.crewBoard .filterElement img:first-child {
    border-radius: 50%;
}
.crewBoard .filterElement img:hover,
.crewBoard .filterElement img.active {
    box-shadow: 0 0 0 2px #ffc53d;
}
.crewBoard .listCrew {
    height: 690px;
    padding-left: 85px;
}
.crewBoard .listCrew .merc {
    margin: 0 10px 40px 10px;
    position: relative;
    height: 234px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.crewBoard .listCrew .merc:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: url(../img/char-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.crewBoard .listCrew .merc:hover,
.crewBoard .listCrew .merc.selected {
    box-shadow: 0px 0px 8px #cca864;
}
.crewBoard .listCrew .merc:hover:before,
.crewBoard .listCrew .merc.selected:before {
    opacity: 1;
}
.crewBoard .listCrew .merc:hover .element,
.crewBoard .listCrew .merc.selected .element {
    box-shadow: 0px 0px 0 2px #fff;
}
.crewBoard .listCrew .element {
    width: 40px;
    position: absolute;
    left: -12px;
    top: -6px;
    z-index: 1;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.crewBoard .listCrew .buttonAction {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    width: 80px;
    cursor: pointer;
    z-index: 1;
}
.crewBoard .listCrew .thumbMercWrap {
    position: relative;
    width: 150px;
    margin: 0 auto;
    height: 135px;
    overflow: hidden;
    border-radius: 12px;
    padding-top: 5px;
}
.crewBoard .listCrew .thumbMercWrap img {
    width: 170px;
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
}
.crewBoard .listCrew .thumbMercWrap img.merc-broke {
    width: 200px;
    position: relative;
    top: -40px;
}
.crewBoard .listCrew .thumbMercWrap img.merc-cross {
    width: 230px;
    position: relative;
    top: -40px;
    left: 10px;
}
.crewBoard .listCrew .thumbMercWrap img.merc-slinger {
    width: 200px;
    position: relative;
    top: -15px;
}
.crewBoard .listCrew .thumbMercWrap img.merc-monie {
    width: 190px;
    position: relative;
    top: -20px;
}
.crewBoard .listCrew .thumbMercWrap img.merc-helsing {
    width: 190px;
    position: relative;
    top: -15px;
    left: -10px;
}
.crewBoard .listCrew .mercOverview {
    position: relative;
}
.crewBoard .listCrew .info {
    text-align: center;
    line-height: 1;
    padding: 10px 5px 26px;
    border-radius: 0 0 10px 10px;
    width: 158px;
    margin-left: 5px;
    background: url(../img/gradient-rectangle.png) center top;
}
.crewBoard .listCrew .info > div {
    margin: 5px 0;
}
.crewBoard .listCrew .star img {
    width: 15px;
}
.crewBoard.metalFrame,
.crewBoard.metalFrame:before {
    background: none;
}
.crewBoard.metalFrame .frameBottom {
    display: block;
    margin: 0 auto;
    border-radius: 0 0 18px 18px;
}
.crewBoard.metalFrame .listCrew {
    position: relative;
    background: url(../img/crew-board-metal-middle.png) repeat-y center top / contain;
}
.crewBoard.metalFrame .listCrew:before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -16px;
    border-radius: 0 0 20px 20px;
    padding-top: 80px;
    background: url(../img/crew-board-metal-bottom.png) no-repeat center center / contain;
}
.crewBoard.metalFrame .filterElement {
    left: 30px;
    top: 100px;
}
.crewBoard.metalFrame .filterRarity {
    position: relative;
    width: calc(100% - 90px);
    margin: 0 auto -8px;
    background: url(../img/bg-frame-metal-rectangular-middle.png) repeat-x left center / contain;
    z-index: 1;
    height: 80px;
    padding: 0;
}
.crewBoard.metalFrame .filterRarity .item:not(:last-child) {
    margin-right: 30px;
}
.crewBoard.metalFrame .filterRarity .symbol {
    width: 35px;
}
.crewBoard.metalFrame .filterRarity .symbol:not(:last-child) {
    margin-right: 25px;
}
.crewBoard.metalFrame .filterRarity .pageName {
    position: relative;
    height: 100%;
    margin-left: -20px;
}
.crewBoard.metalFrame .filterRarity .pageName img {
    position: relative;
    z-index: 1;
}
.crewBoard.metalFrame .filterRarity .pageName .text {
    color: #871400;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    font-size: 30px;
}
.crewBoard.metalFrame .filterRarity:before,
.crewBoard.metalFrame .filterRarity:after,
.crewBoard.metalFrame .filterRarity .pageName:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    left: -45px;
    top: 0;
    background: url(../img/bg-frame-metal-rectangular-left.png) no-repeat left center / contain;
}
.crewBoard.metalFrame .filterRarity:after,
.crewBoard.metalFrame .filterRarity .pageName:after {
    left: auto;
    right: -45px;
    transform: scaleX(-1);
}
.crewBoard.metalFrame .filterRarity .pageName:after {
    right: -25px;
}
.crewBoard.metalFrame .mCustomScrollbar .mCSB_scrollTools {
    right: 12px;
    top: 5px;
    background: #2e1009;
    width: 25px;
    border-radius: 0 0 15px 0;
}
.crewBoard.metalFrame .mCSB_scrollTools .mCSB_draggerContainer {
    left: -15px;
}
.crewBoard.stoneFrame {
    width: 767px;
    height: 910px;
    background: url(../img/crew-board-stone.png) no-repeat center top / cover;
}
.crewBoard.stoneFrame:before {
    display: none;
}
.crewBoard.stoneFrame .filterRarity {
    color: #FFC069;
    padding: 47px 75px 42px 75px;
}
.crewBoard.stoneFrame .filterRarity .item:not(:last-child) {
    margin-right: 30px;
}
.crewBoard.stoneFrame .filterElement {
    left: 8px;
    top: 110px;
}
.crewBoard.stoneFrame .listCrew {
    height: 680px;
}
.crewBoard.stoneFrame .listCrew .merc {
    height: 250px;
    margin-bottom: 20px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap {
    height: 175px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img {
    width: 200px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img.merc-broke {
    width: 240px;
    top: -50px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img.merc-cross {
    width: 270px;
    top: -50px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img.merc-slinger {
    width: 215px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img.merc-monie {
    width: 220px;
}
.crewBoard.stoneFrame .listCrew .thumbMercWrap img.merc-helsing {
    width: 220px;
}
.crewBoard.stoneFrame .listCrew .info {
    padding: 5px;
    width: 100%;
}
.crewBoard.stoneFrame .mCustomScrollbar .mCSB_scrollTools {
    right: 12px;
    top: 5px;
    background: #191D39;
    width: 34px;
    border-radius: 5px;
}
.crewBoard.stoneFrame .mCSB_scrollTools .mCSB_draggerContainer {
    left: -11px;
}
/*========================== FOW ==========================*/
#fowPage {
    position: relative;
    padding-bottom: 150px;
    background: #fff url("../img/bg-fow.jpg") no-repeat 50%/cover;
}
#fowPage .fowFrameTop {
    position: relative;
    top: -10px;
    margin-bottom: 20px;
}
#fowPage .balance {
    margin-left: 40px;
}
#fowPage .fowPageFooterImg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
#fowPage .fowFrame {
    width: 1300px;
    height: 637px;
    margin: 30px auto 0;
    padding: 0 70px 180px;
    background: url("../img/fow-frame.png") no-repeat 50%/cover;
}
#fowPage .fowFrame .wishList .item {
    height: 100%;
    padding: 20px 50px 20px;
    background: linear-gradient(180deg, #873800 0%, rgba(135, 56, 0, 0.58619) 41.38%, rgba(135, 56, 0, 0) 100%);
    border-radius: 16px;
    text-align: center;
    color: #FFC069;
}
#fowPage .fowFrame .wishList .item .title {
    color: #fff;
    font-size: 22px;
}
#fowPage .fowFrame .wishList .item .icon {
    margin: 20px 0;
}
#fowPage .fowFrame .btn {
    position: relative;
    z-index: 1;
}
#fowPage .fowFrame .comingSoon {
    filter: grayscale(1);
}
/*========================== TOKEN VESTING ==========================*/
#tokenVesting,
#tokenVesting .wrap {
    height: 100vh;
    font-size: 14px;
    background-color: #fff;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#tokenVesting h1 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    color: black;
}
#tokenVesting form {
    width: 500px;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#tokenVesting .status {
    text-align: center;
    margin-bottom: 30px;
}
#tokenVesting .status img {
    width: 30px;
    margin-right: 3px;
}
#tokenVesting .status .walletAddress {
    margin-bottom: 10px;
    font-weight: 500;
    color: black;
}
#tokenVesting .info {
    text-align: center;
    padding: 0 100px;
    color: black;
}
#tokenVesting .info ul.list-unstyled {
    padding: 5px 0;
}
#tokenVesting .info ul.list-unstyled li {
    margin: 10px 0;
}
#tokenVesting .dropdown button {
    width: 100%;
    display: block;
    text-align: left;
    border: 1px solid #e3e3e3;
    background: none;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}
#tokenVesting .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top-color: #a5a5a5;
}
#tokenVesting .dropdown-menu {
    font-size: 14px;
    width: 100%;
    border-color: #e3e3e3;
    padding: 0;
}
#tokenVesting .dropdown-menu .dropdown-item {
    padding: 10px;
}
#tokenVesting .dropdown-item:focus,
#tokenVesting .dropdown-item:hover {
    background-color: #f9f9f9;
    color: #000;
}
#tokenVesting .dropdown-menu li:not(:last-child) .dropdown-item {
    border-bottom: 1px solid #e7e7e7;
}
#tokenVesting .refresh {
    margin-bottom: 20px;
}
#tokenVesting .btn {
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    font-size: 14px;
}
#tokenVesting .btn:focus {
    outline: none;
    box-shadow: none;
}
#tokenVesting .btn.btn-outline {
    padding: 1px;
}
#tokenVesting .btn.btn-outline > span {
    position: relative;
    display: block;
    height: 100%;
    background: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    color: #ff9600;
}
#tokenVesting .btn.btn-outline > span > span {
    color: #ff9600;
    background: linear-gradient(to right, #ffcc33 0%, #ff9600 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#tokenVesting .btn-grad-1 {
    background-image: linear-gradient(to right, #de0300 0%, #ff8100 51%, #de0300 100%);
}
#tokenVesting .btn-grad-2 {
    background-image: linear-gradient(to right, #02AAB0 0%, #1fe8c8 51%, #02AAB0 100%);
}
#tokenVesting .btn-grad-3 {
    background-image: linear-gradient(to right, #ff9600 0%, #ffcc33 51%, #ff9600 100%);
}
#tokenVesting .btn-grad-1:hover,
#tokenVesting .btn-grad-2:hover,
#tokenVesting .btn-grad-3:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}
/*========================== TARVEN SHOP ==========================*/
#tarvenShopPage {
    background: url(../img/bg-tarven-shop.jpg) no-repeat center center / cover;
}
#tarvenShopPage .mercFrameMetal {
    font-size: 16px;
}
#tarvenShopPage .price {
    color: #FFC53D;
}
/*========================== MODAL ==========================*/
.modalOverlay {
    z-index: 1056;
    background: rgb(38 38 38 / 60%);
}
.modal .textHeading {
    position: absolute;
    color: #FFF7E6;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    text-align: center;
    padding-left: 12px;
    font-size: 30px;
}
.modal .modal-content {
    background: none;
    border: none;
}
.modal .modal-body {
    padding: 0;
}
.modal .closeModal {
    cursor: pointer;
}
.modal .commonMessageRibbonFrame {
    width: 500px;
    height: 324px;
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    text-align: center;
    background: url(../img/common-message-ribbon-frame.png) no-repeat center center / cover;
}
.modal .commonMessageRibbonFrame .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
.modal .commonMessageRibbonFrame .buttonAction {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
.modal .messageStarFrame {
    width: 100%;
    height: 325px;
    color: #FFD591;
    text-align: center;
    padding: 180px 40px 0;
    font-size: 18px;
    background: url(../img/message-star-frame.png) no-repeat center center / cover;
}
.modal .messageStarFrame .closeModal {
    position: absolute;
    right: -10px;
    top: 44px;
    width: 50px;
    cursor: pointer;
}
.modal .messageFrameMetal,
.modal .messageStarFrameMetal {
    width: 400px;
    height: 266px;
    text-align: center;
    color: #FFD591;
    padding: 100px 40px 40px;
    font-size: 16px;
    background: url(../img/message-star-frame-metal.png) no-repeat center center / contain;
}
.modal .messageFrameMetal {
    width: 450px;
    height: 270px;
    padding: 50px 30px;
    background-image: url(../img/message-frame-metal.png);
}
.modal .messageFrameMetal .closeModal,
.modal .messageStarFrameMetal .closeModal {
    position: absolute;
    right: -20px;
    width: 65px;
    top: 30px;
}
.modal .messageFrameMetal .closeModal {
    top: -5px;
    right: -25px;
}
.modal .messageFrameMetal .buttonText {
    position: absolute;
    bottom: -32px;
}
.modal .signSuccess {
    margin-bottom: -190px;
}
.modal .listReward .top {
    margin-bottom: 50px;
}
.modal .listReward .signSuccess {
    margin: 0;
    width: 150px;
    height: 200px;
    padding-top: 30px;
    margin: 0 10px 40px 10px;
}
.modal .listReward .signSuccess:before {
    width: 53px;
    height: 59px;
    top: -30px;
    left: -10px;
}
.modal .listReward .signSuccess .character {
    width: 105px;
    position: relative;
    top: -10px;
}
.modal .listReward .signSuccess .character.merc-broke {
    width: 125px;
    top: -30px;
}
.modal .listReward .signSuccess .character.merc-cross {
    width: 130px;
    top: -20px;
}
.modal .listReward .signSuccess .element {
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
}
.modal .listReward .signSuccess .star {
    position: absolute;
    left: 45px;
    top: -2px;
}
.modal .listReward .signSuccess .star img {
    width: 14px;
}
.tarvenShopModal .quantity {
    color: #FFE7BA;
}
.tarvenShopModal .quantity .number {
    font-size: 50px;
    line-height: 1;
    padding: 0 20px;
}
.tarvenShopModal .badgeOri {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
}
.tarvenShopModal .badgeOri .text {
    position: absolute;
    left: 50%;
    font-size: 30px;
    top: 15px;
}
.tarvenShopModal .total {
    position: absolute;
    width: 250px;
    height: 60px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    margin-left: -25px;
    padding-right: 90px;
    background: url(../img/bg-frame-metal.png) no-repeat center center / contain;
}
.tarvenShopModal .total .btn {
    position: absolute;
    right: -50px;
}
.tarvenShopModal .total .symbol {
    position: absolute;
    width: 45px;
    right: 60px;
}
.tarvenShopModal .total .number {
    width: 135px;
    font-size: 35px;
    position: relative;
    top: -2px;
    left: 10px;
}
#confirmSellModal .mercSellThumb {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
#confirmSellModal .mercSellThumb:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../img/cri-thumb-frame.png) no-repeat center center / cover;
}
#confirmSellModal .mercSellThumb .element {
    position: absolute;
    width: 30px;
    top: -6px;
    left: -10px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 2;
}
#confirmSellModal .mercSellThumb .thumbMercWrap {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow: hidden;
    border-radius: 18px;
    z-index: 1;
}
#confirmSellModal .mercSellThumb .thumbMercWrap img {
    width: 140px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
}
#confirmSellModal .confirm .text {
    font-size: 20px;
}
#confirmSellModal .confirm .number {
    color: #fff;
    font-size: 38px;
    line-height: 100%;
    margin-top: 10px;
}
#confirmSellModal .confirm .number img {
    width: 45px;
}
#winModal .winFrame {
    width: 800px;
    height: 520px;
    margin: 0 auto;
    padding: 280px 0 0 0;
    font-size: 18px;
    text-align: center;
    background: url(../img/modal-win.png) no-repeat center center / cover;
}
#winModal .bellyReceived {
    font-size: 50px;
    color: #FFE922;
    margin-top: 15px;
    line-height: 1;
}
#winModal .bellyReceived img {
    width: 60px;
    margin-left: 15px;
}
#winModal .xpReceived {
    color: #fdbe01;
    font-size: 25px;
}
#winModal .closeModal,
#lossModal .lossFrame .closeModal {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    cursor: pointer;
}
#lossModal .lossFrame {
    width: 800px;
    height: 370px;
    padding-top: 190px;
    font-size: 18px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    background: url(../img/modal-loss.png) no-repeat center center / cover;
}
#commonMessageModal .commonMessageFrame {
    width: 537px;
    height: 280px;
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    padding: 40px;
    text-align: center;
    background: url(../img/common-message-frame.png) no-repeat center center / cover;
}
#commonMessageModal .closeModal {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 50px;
    cursor: pointer;
}
#confirmSelectedTeamModal .confirmSelectedTeamFrame {
    position: relative;
    width: 513px;
    height: 506px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #FFD591;
    background: url(../img/confirm-selected-team-frame.png) no-repeat center center / cover;
}
#confirmSelectedTeamModal .selectedTeam .listMerc {
    position: relative;
    z-index: 1;
    padding: 0 35px;
    margin: 100px 0 20px;
}
#confirmSelectedTeamModal .selectedTeam .listMerc .merc {
    margin: 8px;
    position: relative;
}
#confirmSelectedTeamModal .selectedTeam .listMerc .element {
    position: absolute;
    width: 25px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 5px;
    left: -10px;
    top: 3px;
    z-index: 1;
}
#confirmSelectedTeamModal .selectedTeam .selectedMercFrame {
    height: 104px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    background: url(../img/selected-merc-frame.png) no-repeat center center / cover;
}
#confirmSelectedTeamModal .selectedTeam .selectedThumbMercWrap {
    height: 92px;
    width: 80px;
    margin-bottom: 8px;
    overflow: hidden;
}
#confirmSelectedTeamModal .selectedTeam .selectedThumbMercWrap img {
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 90px;
}
#confirmSelectedTeamModal .selectedTeam .star {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    width: 90px;
    text-align: center;
    padding: 5px;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / 90px 21px;
}
#confirmSelectedTeamModal .selectedTeam .star img {
    width: 10px;
}
#confirmSelectedTeamModal .closeModal {
    position: absolute;
    right: -10px;
    top: 40px;
    cursor: pointer;
}
#confirmSelectedTeamModal .goToBattleField {
    position: absolute;
    bottom: -20px;
    width: 230px;
    left: 50%;
    transform: translateX(-50%);
}
#disconnectModal .disconnectModalFrame .textHeading {
    font-size: 22px;
    line-height: 1.1;
}
#disconnectModal .disconnectModalFrame .walletAddress {
    color: #FFE7BA;
    margin-top: 60px;
    margin-bottom: 20px;
}
#disconnectModal .disconnectModalFrame .totalBelly {
    font-size: 50px;
    color: #FFFBDD;
}
#disconnectModal .disconnectModalFrame .totalBelly img {
    width: 60px;
}
#disconnectModal .disconnectModalFrame .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
#disconnectModal .disconnectModalFrame .buttonDisconnect {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
#connectWalletModal .textHeading {
    font-size: 22px;
    line-height: 1.1;
}
#connectWalletModal .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
#connectWalletModal .listWallet {
    width: 100%;
    padding: 80px 50px 0;
}
#connectWalletModal .listWallet li {
    border: 1px solid #c9c5a2;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
}
#connectWalletModal .listWallet .icon {
    width: 35px;
}
#claimBellyModal .modal-body {
    padding: 150px 40px 0;
}
#claimBellyModal .buttonClaim {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
#claimBellyModal .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
#returnMercModal .buttonText {
    position: absolute;
    bottom: -18px;
}
#returnMercModal .sellingPrice {
    color: #FFD591;
    margin-top: 20px;
    font-size: 22px;
}
#returnMercModal .sellingPrice .symbol {
    width: 38px;
    margin-left: 5px;
}
#buyPackageModal .modal-body {
    padding: 20px;
    background: rgb(0 0 0 / 30%) url(../img/bg-treasure.png) no-repeat center bottom / contain;
}
#buyPackageModal .inner {
    max-width: 1400px;
    margin: 0 auto;
}
#buyPackageModal .bonus {
    margin: 0 30px;
}
#buyPackageModal .bonus img {
    width: 160px;
}
#buyPackageModal .bonus > div {
    position: relative;
}
#buyPackageModal .bonus > div + div {
    margin-top: 20px;
}
#buyPackageModal .bonus .totalBonus {
    position: absolute;
    left: 70px;
    top: 18px;
    line-height: 1;
    white-space: nowrap;
    font-size: 11px;
}
#buyPackageModal .bonus .ticket .totalBonus {
    top: 30px;
}
#buyPackageModal .bonus .number {
    font-size: 20px;
}
#buyPackageModal .bonus .ori .text {
    padding-left: 20px;
}
#speedBattleModal .modal-body {
    padding: 35px 0 80px 0;
}
#speedBattleModal .level,
#speedBattleModal .team {
    position: relative;
}
#speedBattleModal ul li {
    position: relative;
}
#speedBattleModal ul li:not(:last-child) {
    margin-bottom: 20px;
}
#speedBattleModal .level input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
#speedBattleModal .level .winingRate {
    background: #33342C;
    padding: 15px;
    border-radius: 16px;
    font-size: 16px;
    color: #E87F45;
}
#speedBattleModal .level li:hover .winingRate,
#speedBattleModal .level input:checked ~ .winingRate {
    background: #6F5F4D;
    color: #FFD6C0;
}
#speedBattleModal .level .status {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    font-size: 12px;
    color: #FFF7E6;
    padding: 2px 0;
    width: 100%;
    text-align: center;
    display: none;
    background: linear-gradient(90deg, rgba(153, 67, 52, 0) 6.97%, #764C25 55.54%, rgba(148, 80, 69, 0) 96.63%);
}
#speedBattleModal .level input:checked ~ .status {
    display: block;
}
#speedBattleModal .title {
    color: #FFF6E4;
}
#speedBattleModal .title:first-child,
#speedBattleModal .level {
    width: 250px;
}
#speedBattleModal .title:last-child {
    width: 300px;
}
#speedBattleModal .crewBoard {
    width: 100%;
    background: none;
}
#speedBattleModal .crewBoard:before {
    display: none;
}
#speedBattleModal .crewBoard,
#speedBattleModal .crewBoard .listCrew {
    height: auto;
    padding: 0;
    overflow: visible;
}
#speedBattleModal .crewBoard .disabled > div{
    opacity: .6;
}
#speedBattleModal .crewBoard .listCrew .merc {
    margin: 0 15px 0 0;
    width: 75px;
    height: 105px;
}
#speedBattleModal .crewBoard .listCrew .merc:before {
    opacity: 1;
}
#speedBattleModal .crewBoard .listCrew .element {
    width: 25px;
    left: -6px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap {
    width: 70px;
    height: 83px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img {
    width: 95px;
    top: -5px;
    left: 3px;
    position: relative;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img.merc-cross {
    width: 125px;
    top: -25px;
    left: 5px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img.merc-broke {
    width: 110px;
    top: -23px;
    left: 0px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img.merc-helsing {
    width: 115px;
    top: -12px;
    left: -5px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img.merc-monie {
    width: 110px;
    top: -12px;
    left: 0px;
}
#speedBattleModal .crewBoard .listCrew .thumbMercWrap img.merc-slinger {
    width: 115px;
    top: -15px;
    left: 3px;
}
#speedBattleModal .crewBoard .listCrew .star {
    margin: 0;
}
#speedBattleModal .crewBoard .listCrew .star img {
    width: 9px;
}
#speedBattleModal .crewBoard .listCrew .info {
    background-size: cover;
    padding: 5px 0;
    width: 70px;
}
#speedBattleModal .charStamina {
    width: 160px;
    margin-bottom: 5px;
}
#speedBattleModal .charStamina .text {
    margin-bottom: 5px;
}
#speedBattleModal .processBar {
    height: 27px;
    padding: 3px 6px 0 4px;
}
#speedBattleModal .processBar .percent {
    height: 17px;
}
#speedBattleModal .cost {
    color: #FFA940;
    margin-bottom: 5px;
}
#speedBattleModal .note {
    color: #FFD591;
    font-size: 12px;
    position: absolute;
    left: 90px;
    bottom: 0;
    background: linear-gradient(90deg, rgba(153, 67, 52, 0) 0%, #9F4D00 35%, rgba(148, 80, 69, 0) 88%);
    width: calc(100% - 90px);
    display: none;
}
#speedBattleModal .crewBoard .disabled .note {
    display: block;
}
#speedBattleModal .inputGroup {
    bottom: 10px;
}
#speedBattleModal .mercFrameMetal.large .inputGroup .inputIcon {
    width: 284px;
    height: 70px;
    font-size: 22px;
    padding: 0 0 0 30px;
}
#speedBattleModal .mercFrameMetal.large .inputGroup img.symbol {
    width: 40px;
    right: 110px;
}
#speedBattleModal .mercFrameMetal.large .inputGroup .buttonText {
    margin-left: -90px;
    width: 160px;
}
#confirmFightModal .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
#confirmFightModal .buttonConfirm {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
#confirmFightModal .inner {
    font-size: 22px;
    margin-top: 50px;
    color: #FFE7BA;
}
#confirmFightModal .inner > div {
    margin: 10px 0;
}
#fightResultModal .modal-body {
    padding: 20px;
    background: rgb(38 38 38 / 60%) url(../img/bg-fight-result.png) no-repeat center bottom / cover
}
#fightResultModal .charId {
    bottom: 0;
    height: 40px;
    font-size: 16px;
    padding: 8px 1px 1px 60px;
    color: #612500;
}
#fightResultModal .listReward .signSuccess {
    margin-bottom: 10px;
}
#fightResultModal .listReward .signSuccess .element {
    top: -8px;
    transform: none;
    left: 15px;
}
#fightResultModal .bonus {
    color: #FFE922;
    font-size: 40px;
    margin: 40px 0 20px;
}
#fightResultModal .bonus .icon {
    width: 50px;
}
#fightResultModal .stats {
    text-align: center;
}
#fightResultModal .stats img {
    width: 65px;
    margin: 0 3px;
}
#fightResultModal .stats .number {
    color: #FFE7BA;
    font-size: 24px;
    line-height: 1;
    margin: 5px 0 0;
}
#fightResultModal .stats .text {
    color: #FA8C16;
}
#fightResultModal .xp {
    padding-left: 60px;
    color: #ffea21;
    font-size: 20px;
    margin-bottom: 5px;
}
#upGradeModal .text {
    color: #FFE7BA;
    font-size: 22px;
    margin: 50px 0 10px;
}
#upGradeModal .totalCost {
    font-size: 40px;
}
#upGradeModal .totalCost > div:first-child {
    margin-right: 40px;
    color: #FFBC00;
}
#upGradeModal .materialReceived .text {
    font-size: 16px;
    margin-bottom: 15px;
}
#upGradeModal .materialReceived .item {
    position: relative;
    margin: 0 10px;
}
#upGradeModal .materialReceived .item:hover .number {
    background: #6a6845;
}
#upGradeModal .materialReceived .item .number {
    padding: 10px 15px;
    border-radius: 10px;
    line-height: 1;
    cursor: pointer;
    font-size: 40px;
}
#upGradeModal .materialReceived input[type="radio"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
#upGradeModal .materialReceived .item:hover input:checked ~ .number,
#upGradeModal .materialReceived input:checked ~ .number {
    background: #4a4827;
}
#upGradeModal .materialReceived input:checked ~ .status {
    display: block;
}
#upGradeModal .materialReceived .status {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    font-size: 12px;
    color: #fff7e6;
    padding: 2px 0;
    width: 100%;
    text-align: center;
    display: none;
    background: linear-gradient(90deg,rgba(153,67,52,0) 6.97%,#764c25 55.54%,rgba(148,80,69,0) 96.63%);
}
#upGradeSuccessModal .signSuccess .star {
    position: absolute;
    left: 100px;
    top: -5px;
}
#upGradeSuccessModal .signSuccess .star img {
    width: 22px;
}
#claimFowModal .modal-body {
    color: #FFC069;
    font-size: 16px;
    background: rgb(38 38 38 / 60%) url(../img/bg-fight-result.png) no-repeat center bottom / cover;
}
#claimFowModal .modal-body .modalFooterImg {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
#claimFowModal .modal-body .frame {
    width: 800px;
    height: 455px;
    text-align: center;
    position: relative;
    background: url(../img/wood-frame.png) no-repeat 50%/cover;
}
#claimFowModal .modal-body .frame .top {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}
#claimFowModal .modal-body .buttonClaimReward {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
    z-index: 1;
}
/*========================== CUSTOM SCROLLBAR ==========================*/
.mCustomScrollbar .mCSB_container {
    padding: 10px 0 0 5px;
}
.mCustomScrollbar .mCSB_inside > .mCSB_container {
    margin: 0;
}
.mCustomScrollbar .mCustomScrollBox {
    overflow-y: hidden;
    overflow-x: auto;
    padding-right: 85px;
}
.mCustomScrollbar .mCSB_scrollTools {
    width: 52px;
    right: 18px;
    top: 20px;
    z-index: 1;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger {
    height: 52px !important;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_draggerRail {
    display: none;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 52px;
    height: 52px;
    cursor: pointer;
    background: transparent url(../img/icon-scroll.png) no-repeat center center / cover;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: transparent;
}
/*========================== RESPONSIVE ==========================*/
@media (max-width: 1680px) {
    .wrap {
        min-width: initial;
        width: 100%;
        padding: 0 20px;
    }
    #battleFieldPage #content {
        padding: 0;
    }
    #battleFieldPage .battleFieldFrame {
        width: 1600px;
        height: 717px;
        padding: 0;
    }
    #battleFieldPage .mercenary {
        height: 180px;
    }
    #battleFieldPage .yourTeam .merc:hover,
    #battleFieldPage .yourTeam .merc.selected {
        transform: scale(1.1);
    }
    #battleFieldPage .mercTeam .charId {
        width: 270px;
        height: 64px;
        margin: 200px 0 0px 140px;
    }
    #battleFieldPage .mercTeam .charId .element {
        width: 80px;
        left: 12px;
        top: -25px;
    }
    #battleFieldPage .mercTeam .charInfo {
        margin: 10px 20px 0 140px;
    }
    #battleFieldPage .mainImg {
        margin-bottom: 20px;
        width: 300px;
    }
    #battleFieldPage .winingRate {
        margin-bottom: 30px;
    }
    #battleFieldPage .criTeam .listCri {
        margin: 0 200px 0 50px;
    }
    /* Battle History */
    #battleHistoryPage .myCrewFrame {
        width: 100%;
    }
    /* My Crew */
    /* Upgrade */
    #teamSettingPage .myCrewFrame {
        width: 100%;
    }
    #teamSettingPage .selectedTeam {
        width: 450px;
        height: 506px;
    }
    #teamSettingPage .mercFullInfo {
        padding: 0 10px;
        width: 360px;
        margin-top: 30px;
    }
    #teamSettingPage .selectedTeam .selectedMercFrame {
        height: 203px;
    }
    /* Upgrade */
    #upgradePage .upgradeMerc {
        width: 815px;
        height: 648px;
        right: -25px;
    }
    #upgradePage .upgradeMerc .mercMystery {
        width: 245px;
    }
    /* Market Place */
    #marketPage .metal-frame-style-1 .avataWrap {
        height: 180px;
        top: 10px;
    }
    #marketPage .mercProfile {
        width: 250px;
    }
    #marketPage .mercProfile .mercName {
        height: 62px;
    }
    #marketPage .mercProfile .action a {
        width: 105px;
        margin: 10px 5px;
    }
    #marketPage .crewBoard {
        width: 1250px;
    }
    #marketPage .crewBoard .listCrew .col {
        width: 14.28%;
    }
    #marketPage .crewBoard .listCrew .merc {
        height: 189px;
    }
    #marketPage .crewBoard .listCrew .thumbMercWrap {
        width: 126px;
        height: 122px;
    }
    /* Sell Merc */
    #sellMercPage .crewBoard.stoneFrame {
        width: 670px;
        height: 798px;
    }
    #sellMercPage .mercFrameMetal.medium {
        width: 630px;
        height: 542px;
    }
    #sellMercPage .mercFrameMetal.medium .mercPortrait,
    #sellMercPage .mercFrameMetal.medium .badgeFrame {
        width: 230px;
    }
    #sellMercPage .mercFrameMetal.medium .star img {
        width: 25px;
    }
    #sellMercPage .mercFrameMetal.medium .sellOption .sellBtn {
        margin: 0;
    }
    #sellMercPage .crewBoard.stoneFrame .filterRarity {
        padding: 40px 75px 40px 50px;
    }
    #sellMercPage .crewBoard.stoneFrame .filterRarity .item:not(:last-child) {
        margin-right: 23px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew {
        padding-left: 75px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .thumbMercWrap {
        height: 144px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .merc {
        height: 217px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .info {
        width: 146px;
    }
    #sellMercPage .mCustomScrollbar .mCustomScrollBox {
        padding-right: 65px;
    }
    /* Buy Package */
    #buyPackagePage .mainImg {
        width: 580px;
    }
    #buyPackagePage .decor-1 {
        left: -50px;
        width: 140px;
    }
    #buyPackagePage .decor-2 {
        width: 320px;
    }
    #buyPackagePage .packageName {
        top: 65px;
        font-size: 25px;
    }
}
@media (max-width: 1551px) {
    .crewBoard {
        width: 720px;
    }
    .crewBoard .listCrew {
        height: 670px;
    }
    .crewBoard .listCrew .merc {
        height: 227px;
    }
    .crewBoard .listCrew .info {
        padding-bottom: 20px;
    }
    #battleFieldPage .battleFieldFrame {
        width: 1520px;
        height: 681px;
    }
    #battleFieldPage .mercTeam .charId,
    #battleFieldPage .mercTeam .charInfo {
        margin-left: 120px;
    }
    #battleFieldPage .criTeam .listCri {
        margin: 0 180px 0 50px;
    }
    #battleFieldPage .mainImg {
        width: 280px;
        max-width: initial;
    }
    #battleFieldPage .winingRate img {
        width: 230px;
    }
    #battleFieldPage .fightFrame {
        width: 230px;
        height: 158px;
    }
    #battleFieldPage .mercenary {
        height: 175px;
    }
    #battleFieldPage .mercenary .element {
        width: 35px;
    }
    /* Team setting page */
    #teamSettingPage .btnBack {
        margin-right: 10px;
    }
    #teamSettingPage .myCrewFrame {
        width: 1520px;
    }
    #teamSettingPage .mercFullInfo {
        width: 290px;
        padding: 0;
    }
    #teamSettingPage .mercFullInfo .mercPortrait {
        width: 290px;
        height: 405px;
    }
    #teamSettingPage .mercFullInfo .mercPortrait img.merc-slinger {
        width: 330px;
        max-width: initial;
    }
    #teamSettingPage .mercFullInfo .mercPortrait img.merc-helsing {
        width: 310px;
        max-width: initial;
    }
    #teamSettingPage .mercFullInfo .mercPortrait img.merc-monie {
        width: 330px;
    }
    #teamSettingPage .mercFullInfo .mercPortrait img.merc-broke {
        width: 370px;
        margin-top: -30px;
    }
    #teamSettingPage .mercFullInfo .mercPortrait img.merc-cross {
        width: 390px;
    }
    #teamSettingPage .selectedTeam {
        width: 430px;
        height: 483px;
    }
    #teamSettingPage .selectedTeam .star {
        height: 40px;
    }
    #teamSettingPage .selectedTeam .star img {
        width: 14px;
    }
    #teamSettingPage .selectedTeam .selectedThumbMercWrap {
        width: 154px;
        height: 175px;
    }
    #teamSettingPage .selectedTeam .selectedThumbMercWrap img {
        width: 170px;
    }
    #teamSettingPage .selectedTeam .selectedMercFrame {
        height: 192px;
    }
    #teamSettingPage .selectedTeam .listMerc .element {
        width: 50px;
    }
    #teamSettingPage .selectedTeam .listMerc .buttonRemove {
        bottom: -22px;
        width: 90px;
    }
    #teamSettingPage .buttonConfirmTeam {
        width: 170px;
    }
    /* Market Place */
    #marketPage .mercProfile .action a {
        width: 100px;
    }
    #marketPage .crewBoard.metalFrame .filterRarity .pageName {
        width: 300px;
    }
    #marketPage .crewBoard {
        width: 1090px;
    }
    #marketPage .crewBoard .listCrew {
        height: 740px;
    }
    #marketPage .crewBoard .listCrew .col {
        width: 16.66666667%;
    }
    #marketPage .crewBoard.metalFrame .filterRarity .item:not(:last-child) {
        margin-right: 20px;
    }
    #marketPage .crewBoard.metalFrame .mCustomScrollbar .mCSB_scrollTools {
        height: 730px;
    }
    /* My Shop */
    #myshopPage .crewBoard {
        width: 1190px;
    }
    #myshopPage .crewBoard .listCrew {
        padding-left: 55px;
    }
    #myshopPage .crewBoard .listCrew .merc {
        height: 219px;
    }
    #myshopPage .crewBoard .listCrew .thumbMercWrap {
        height: 150px;
    }
    #myshopPage .crewBoard .listCrew .info {
        width: 147px;
        padding-top: 5px;
    }
    #myshopPage .crewBoard .listCrew .buttonAction {
        width: 70px;
        bottom: -28px;
    }
    #myshopPage .mCustomScrollbar .mCustomScrollBox {
        padding-right: 55px;
    }
}
@media (max-width: 1350px) {
    .wrap {
        max-width: 1330px;
        overflow: auto;
    }
    .btnBack {
        margin-right: 0;
    }
    /* header */
    header .nav-link {
        width: 130px;
        height: 93px;
        padding-top: 15px;
    }
    header .balance .item {
        padding: 5px 30px 5px 50px;
    }
    /* Team Setting Page */
    #teamSettingPage .myCrewFrame {
        width: 100%;
    }
    #teamSettingPage .crewBoard {
        width: 660px;
    }
    #teamSettingPage .crewBoard:before {
        top: 190px;
    }
    #teamSettingPage .crewBoard .listCrew {
        padding-left: 75px;
    }
    #teamSettingPage .crewBoard .listCrew .merc {
        height: 208px;
    }
    #teamSettingPage .crewBoard .listCrew .thumbMercWrap {
        width: 138px;
        height: 120px;
    }
    #teamSettingPage .crewBoard .listCrew .info {
        width: 140px;
        padding-top: 5px;
        font-size: 13px;
    }
    #teamSettingPage .crewBoard .listCrew .star img,
    #teamSettingPage .selectedTeam .star img {
        width: 13px;
    }
    .crewBoard .listCrew .thumbMercWrap img {
        width: 150px;
    }
    #teamSettingPage .crewBoard .filterRarity {
        padding-bottom: 20px;
    }
    #teamSettingPage .mercFullInfo {
        padding: 0;
        width: 290px;
    }
    #teamSettingPage .mercFullInfo .mercPortrait {
        width: 280px;
        height: 390px;
    }
    #teamSettingPage .selectedTeam {
        width: 340px;
        height: 382px;
    }
    #teamSettingPage .selectedTeam:before {
        width: 260px;
    }
    #teamSettingPage .selectedTeam .imgMyTeam {
        width: 270px;
        top: -120px;
    }
    #teamSettingPage .selectedTeam .star {
        width: 130px;
        height: 45px;
        padding-top: 6px;
        bottom: 0;
    }
    #teamSettingPage .selectedTeam .selectedMercFrame {
        height: 153px;
    }
    #myCrewPage .selectedTeam .selectedThumbMercWrap {
        width: 125px;
        height: 143px;
    }
    #teamSettingPage .selectedTeam .selectedThumbMercWrap img {
        width: 150px;
    }
    #teamSettingPage .selectedTeam .listMerc .element {
        width: 40px;
        left: -15px;
    }
    #teamSettingPage .selectedTeam .listMerc .merc {
        padding: 15px 10px;
    }
    #teamSettingPage .selectedTeam .listMerc .buttonRemove {
        bottom: -20px;
        width: 80px;
    }
    #teamSettingPage .mCustomScrollbar .mCustomScrollBox {
        padding-right: 75px;
    }
    /* Market Place */
    #marketPage .metal-frame-style-1 .avataWrap {
        height: 145px;
    }
    #marketPage .mercProfile {
        width: 210px;
    }
    #marketPage .mercProfile .mercName {
        height: 52px;
    }
    #marketPage .mercProfile .action a {
        width: 90px;
    }
    /* Sell Merc */
    #sellMercPage .crewBoard.stoneFrame {
        width: 600px;
        height: 714px;
    }
    #sellMercPage .crewBoard.stoneFrame .filterRarity {
        padding: 35px 75px 30px 45px;
    }
    #sellMercPage .crewBoard.stoneFrame .filterElement {
        top: 90px;
        left: 5px;
    }
    #sellMercPage .crewBoard.stoneFrame .filterRarity .item:not(:last-child) {
        margin-right: 16px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew {
        height: 620px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .merc {
        height: 184px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .thumbMercWrap {
        height: 114px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .thumbMercWrap img {
        width: 150px;
    }
    #sellMercPage .crewBoard.stoneFrame .listCrew .info {
        width: 122px;
        font-size: 12px;
    }
    #sellMercPage .mercFrameMetal.medium {
        width: 580px;
        height: 500px;
    }
    #sellMercPage .mercFrameMetal.medium .torch {
        width: 60px;
    }
    #sellMercPage .mercFrameMetal.medium .mercPortrait {
        width: 210px;
    }
    #sellMercPage .mCustomScrollbar .mCSB_scrollTools {
        width: 25px;
        right: 10px;
    }
    #sellMercPage .mCSB_scrollTools .mCSB_draggerContainer {
        left: -16px;
    }
    /* Buy Package */
    #buyPackagePage {
        padding: 50px 30px;
    }
    #buyPackagePage .mainImg {
        width: 530px;
    }
    #buyPackagePage .decor-1 {
        display: none;
    }
    #buyPackagePage .decor-2 {
        width: 300px;
    }
    #buyPackagePage .packageName {
        top: 65px;
        left: 65px;
        font-size: 22px;
    }
    #buyPackagePage .packageName .remain {
        font-size: 18px;
    }
    #buyPackagePage .price img {
        width: 240px;
    }
}