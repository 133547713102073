.body-token-vesting {
    font-size: 20px;
    color: #fff;
    font-family: MorthLand;
    background: url(../img/bg-main.jpg) no-repeat bottom center / cover;
}
/*img {*/
/*	max-width: 100%;*/
/*}*/
.wrap-token-vesting {
    padding-top: 50px;
}
.logo-token-vesting {
    margin-bottom: 40px;
}
.box-token-vesting {
    width: 620px;
    height: 503px;
    padding: 60px 70px 50px;
    background: url(../img/bg-box.png) no-repeat center center / contain;
}
.font-riverAdventurer-token-vesting {
    font-family: RiverAdventurer;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.connectWallet-token-vesting {
    display: inline-block;
    margin-top: 100px;
}
.walletAddress-token-vesting img {
    margin-right: 10px;
}
.token-token-vesting {
    color: #FFE7BA;
    max-width: 300px;
    margin: 30px auto;
}
.claim-token-vesting {
    display: inline-block;
    width: 150px;
}
@media (max-width: 576px) {
    .body-token-vesting {
        padding: 0 10px;
        font-size: 15px;
    }
    .logo-token-vesting {
        margin-bottom: 20px;
        width: 180px;
    }
    .font-riverAdventurer-token-vesting {
        font-size: 23px;
        margin-bottom: 10px;
    }
    .box-token-vesting {
        width: 100%;
        height: 290px;
        padding: 35px 45px 20px;
    }
    .claim-token-vesting {
        width: 90px;
    }
    .token-token-vesting {
        margin: 15px auto 10px;
    }
    .walletAddress-token-vesting img {
        width: 30px;
    }
    .connectWallet-token-vesting {
        width: 160px;
        margin-top: 50px;
    }
}